<template>
  <div v-if="measurementPointOperator" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="measurementPointOperator">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('name')"
            label-for="name"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="measurementPointOperator.name"
              type="text"
              :placeholder="$t('name')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('msbNumber')"
            label-for="msbNumber"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="measurementPointOperator.msbNumber"
              type="text"
              :placeholder="$t('msbNumber')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('street')"
            label-for="street"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="measurementPointOperator.street"
              type="text"
              :placeholder="$t('street')"
            />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('streetNumber')"
            label-for="streetNumber"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="measurementPointOperator.streetNumber"
              type="text"
              :placeholder="$t('streetNumber')"
            />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('zip')"
            label-for="zip"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="measurementPointOperator.zip"
              type="text"
              :placeholder="$t('zip')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('city')"
            label-for="city"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="measurementPointOperator.city"
              type="text"
              :placeholder="$t('city')"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12"> </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="measurementPointOperator"
            modelEditRoute="MeasurementPointOperatorEditPage"
            modelListRoute="MeasurementPointOperatorsPage"
            modelRouteParamName="measurementPointOperatorNumber"
            :updateCallback="updateMeasurementPointOperator"
            :createCallback="createMeasurementPointOperator"
            :deleteCallback="deleteMeasurementPointOperator"
            :fetchCallback="fetchMeasurementPointOperators"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'MeasurementPointOperatorEditPage',
  components: {
    Widget,
    Breadcrumbs,
    ButtonBar,
  },
  props: {
    measurementPointOperatorNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      user: {},
      buttonsDisabled: false,
    };
  },
  computed: {
    ...mapGetters(['getMeasurementPointOperator', 'getMeasurementPointOperatorByNumber']),
    measurementPointOperator() {
      return this.getMeasurementPointOperatorByNumber(this.number);
    },
    buttons() {
      return {
        showDeleteButton:
          this.measurementPointOperator && this.measurementPointOperator.number !== '_new'
            ? true
            : false,
      };
    },
    isNew() {
      return this.measurementPointOperator.id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        {
          name: this.$t('measurementPointOperator'),
          route: { name: 'MeasurementPointOperatorsPage' },
        },
        {
          name: this.measurementPointOperator.number !== '_new' ? this.$t('edit') : this.$t('new'),
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initMeasurementPointOperator',
      'fetchMeasurementPointOperators',
      'fetchMeasurementPointOperatorByNumber',
      'updateMeasurementPointOperator',
      'createMeasurementPointOperator',
      'deleteMeasurementPointOperator',
    ]),
  },
  async mounted() {
    if (!this.measurementPointOperator && this.measurementPointOperatorNumber) {
      this.number = this.measurementPointOperatorNumber;
      this.fetchMeasurementPointOperatorByNumber(this.number);
    } else {
      this.initMeasurementPointOperator();
      this.number = '_new';
    }

    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadMeasurementPointOperatorAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style scoped lang="scss">
.measurementPointOperatorContacts {
  text-align: right;
}
.trash-contact {
  cursor: pointer;
}
legend {
  position: relative;
}
.button-right {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 2px;
}
</style>
